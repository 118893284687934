:root {
  --navbar-height: 56px;
  --navbar-height-mobile: 50px;
  --nav-button-side: 4rem;
  --nav-button-side-mobile: 29px;
  --nav-button-icon-size: 25px;
  --nav-button-icon-size-mobile: 25px;
  --max-content-width: 1280px;
  --regular-content-width: 960px;
  --box-shadow: 0px 0px 20px #00000029;
  --button-box-shadow: 0px 5px 10px #0000007a;
  --height-under-navbar: calc(100vh - var(--navbar-height));
  --height-under-navbar-mobile: calc(100vh - var(--navbar-height-mobile));
}

@font-face {
  font-family: "Avenir";
  src: url(../fonts/AvenirLTStd-Light.otf) format("opentype");
}

@font-face {
  font-family: "Avenir";
  src: url(../fonts/AvenirLTStd-Medium.otf) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Avenir";
  src: url(../fonts/AvenirLTStd-Heavy.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Didot";
  src: url(../fonts/Didot-Italic.woff2) format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "BuenosAires";
  src: url(../fonts/BuenosAires-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "BuenosAires";
  src: url(../fonts/BuenosAires-SemiBold.woff2) format("woff2");
  font-weight: 450;
}

@font-face {
  font-family: "BuenosAires";
  src: url(../fonts/BuenosAires-Bold.woff2) format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "BuenosAires";
  src: url(../fonts/BuenosAires-Black.woff2) format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Medium.ttf) format("truetype");
}

*,
*::after,
*::before {
  font-family: "Avenir", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  box-sizing: border-box;
}

html {
  color: #666;

  // Very annoying but messes up too many things if removed
  font-size: 62.5%;
  scroll-behavior: smooth;
}

* {
  -webkit-overflow-scrolling: touch;
}

h1,
h2{
  font-family: "BuenosAires", sans-serif;
}

h3,
h4,
h5,
h6,
p,
button,
a {
  font-family: "Avenir", sans-serif;
}

a {
  color: #666;
}

a:link,
a:visited {
  text-decoration: none;
}

.button {
  border: none;

  &:hover {
    color: inherit;
  }
}
